import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {Card, FormControl, Grid, RadioGroup, Typography} from "@mui/material";
import {AppContext} from "../../App";
import {useTranslation} from "react-i18next";
import BasketOverview from "./basketOverview";
import {TAddress, TAddressType} from "../../common/struct/models/TAddress";
import Loader from "../../components/loader/loader";
import {TViolation} from "../../common/struct/models/TViolation";
import AddressForm from "./addressForm";
import {StoreContext} from "../../common/struct/store";
import {getUserAddresses} from "../../services/ParticipantService";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import BasketShippingAddressCard from "./basketShippingAddressCard";
import BasketCreateAddress from "./basketCreateAddress";


export default function BasketShippingAddress(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [store] = useContext<StoreContext>(AppContext);
  const [violations, setViolations] = useState<TViolation[]>([]);
  const [loading, setLoading] = useState(true);

  const [address, setAddress] = useState<TAddress|undefined>(undefined);
  const [lastAddress, setLastAddress] = useState<TAddress|undefined>(undefined);
  const [selectedAddress, setSelectedAddress] = useState<TAddress|undefined>(undefined);

  useEffect(() => {
    document.title = t("tab_title.basket2");
  }, []);

  useEffect(() => {
    const operationParticipantId = getOperationParticipantId();
    if (operationParticipantId == null) {
      navigate(PUBLIC_URL.HOME);
      return;
    }
    if (!store.basketConfirmed) {
      navigate(PUBLIC_URL.BASKET);
      return;
    }

    getUserAddresses(operationParticipantId)
      .then(result => {
        const address = result.addresses.find(address => address.type == TAddressType.DELIVERY);
        const lastAddress = result.lastAddresses.find(address => address.type == TAddressType.DELIVERY);
        setAddress(address);
        setSelectedAddress(address);
        setLastAddress(lastAddress);
      })
      .finally(() => setLoading(false))
  }, [])

  const paddingH = store.isMobile ? 2 : 5;
  const paddingV = store.isMobile ? 4 : 6;

  const updateAddress = (address: TAddress): void => {
    (selectedAddress?.id) ? setAddress(address) : setLastAddress(address);
    setSelectedAddress(address);
  }

  return loading ? <Loader size={75} sx={{position: "sticky", top: "0%", height: "100vh", mt: "-181px"}}/> :
    <Grid container justifyContent="center" columns={10} columnSpacing={5} sx={{px: paddingH}}>
      <Grid item xs={10} sm={6} sx={{display: "flex", flexDirection: "column", mb: store.isMobile ? 6 : "0px", py: paddingV}}>
        <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 4}}>
          {t("basket.shipping.title")}
        </Typography>
        {address && <FormControl sx={{width: "100%"}}>
          <RadioGroup>
            <BasketShippingAddressCard address={address} isMobile={store.isMobile} favorite={true} selected={selectedAddress} setSelected={setSelectedAddress} violations={violations} loading={loading}/>
            {lastAddress?.id == undefined && <BasketCreateAddress type={TAddressType.DELIVERY} isMobile={store.isMobile} selected={selectedAddress} setSelected={setSelectedAddress} violations={violations} loading={loading}/>}
          </RadioGroup>
        </FormControl>}
        {address == null && <Card sx={{mb: 4, border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column", p: store.isMobile ? 4 : 6}}>
          <AddressForm loading={loading} type={TAddressType.DELIVERY} setAddress={updateAddress} violations={violations}/>
        </Card>}
      </Grid>
      <Grid item xs={10} sm={3} sx={{ml: store.isMobile ? "0px" : 6}}>
        <BasketOverview step={2} setViolations={setViolations} shippingAddress={selectedAddress} favoriteShippingAddress={selectedAddress?.id == address?.id??""} sx={{py: paddingV}}/>
      </Grid>
    </Grid>
}
