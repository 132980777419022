import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../App";
import {Box, Card, Grid, MenuItem, Typography} from "@mui/material";
import faqImage from "../../assets/faq.svg";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {MAX_DESKTOP_SIZE} from "../../tokens/libertyTheme";
import {useTranslation} from "react-i18next";
import {FaqSection} from "./faqSection";
import {FaqCardSectionTitle, FaqCardTitle} from "./faqCard";
import AskForMore from "./askForMore";
import {useNavigate} from "react-router-dom";

export default function Faq(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const STORE = useContext<any>(AppContext);
  const [store] = STORE;

  useEffect(() => {
    document.title = t("tab_title.faq");
  }, []);

  let oldScroll = 0;
  const [showOrder, setShowOrder] = useState(false);
  const [showDelivery, setShowDelivery] = useState(false);
  const [showStock, setShowStock] = useState(false);
  const [showTopUp, setShowTopUp] = useState(false);
  const [showTechnical, setShowTechnical] = useState(false);
  const [onGoingSection, setOnGoingSection] = useState(window.location.hash.replace("#","")||FaqSection.ORDER);
  const [scrollPosition, setScrollPosition] = useState(0);
  const checkSection = (): void => {
    for (const section in FaqSection) {
      const element = document.getElementById(section.toLowerCase());
      if (element && window.scrollY >= element.offsetTop) {
        setOnGoingSection(section);
        break;
      }
    }

    if (oldScroll > window.scrollY) {
      setScrollPosition(98);
    } else {
      setScrollPosition(32);
    }
    oldScroll = window.scrollY;
  }

  useEffect(() => {
    if (onGoingSection === FaqSection.ORDER) {
      window.scrollTo(0, 0);
    } else {
      const element = document.getElementById(onGoingSection);
      element?.scrollIntoView({block: "start"});
    }
    window.addEventListener("scroll", checkSection);

    return (): void => window.removeEventListener("scroll", checkSection);
  }, [])

  useEffect(() => {
    navigate(PUBLIC_URL.FAQ + '#' + onGoingSection.toLowerCase(), {replace: true})
  }, [onGoingSection])

  const goToSection = (section: FaqSection): void => {
    if (section === FaqSection.ORDER) {
      window.scrollTo(0, 0);
    } else {
      const element = document.getElementById(section);
      element?.scrollIntoView({block: "start"});
    }
    setOnGoingSection(section);
  }

  function SectionMenu(props: {section: FaqSection}): JSX.Element {
    const isSelected = onGoingSection.toString().toLowerCase() === props.section;
    return (
      <MenuItem onClick={(): void => goToSection(props.section)}
        sx={{backgroundColor: isSelected ? "ornament.dark" : "none", py: 2, px: 4, borderLeft: isSelected ? "2px solid" : "1px solid", borderColor: isSelected ? "neutral.dark" : "ornament.dark"}}>
        <Typography variant="body2" color={isSelected ? "neutral.dark" : "neutral.main"} sx={{fontWeight: "medium"}}>{t(`faq.${props.section}.title`)}</Typography>
      </MenuItem>
    )
  }

  const maxWidth = (store.isMobile) ? "100vw" : MAX_DESKTOP_SIZE;
  return (
    <Box sx={{minHeight: !store.isMobile ? "calc(100vh - 182px)" : "calc(100vh - 292px)"}}>
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Box sx={{width: "100%", display: "flex", justifyContent: "center", background: "linear-gradient(91.15deg, #1857B9 0%, #3875D4 100%)"}}>
          <Box sx={{width: "100%", maxWidth: maxWidth, display: "flex", flexDirection: store.isMobile ? "column" : "row", justifyContent: "space-between", py: !store.isMobile ? 4 : 3}}>
            <Box sx={{display: "flex", flexDirection: "column", px: !store.isMobile ? "0px" : 3}}>
              <Breadcrumb breadcrumb={[{label: t("faq.home"), url: PUBLIC_URL.HOME}, {label: t("faq.menu")}]} sx={{mb: 2}} />
              <Box sx={{display: "flex", flexDirection: store.isMobile ? "column" : "row", pb: !store.isMobile ? 4 : 3}}>
                <Typography variant="h1" color="ornament.light" sx={{fontWeight: "bold", zIndex: "1", mr: 1}}>
                  {t("faq.title")}
                </Typography>
                <Typography variant="h1" color="ornament.light" sx={{fontWeight: "medium", zIndex: "1"}}>
                  {t("faq.subtitle")}
                </Typography>
              </Box>
              <Typography variant="body2" color="ornament.light">{t("faq.description")}</Typography>
            </Box>
            <img style={{padding: !store.isMobile ? "0 20px" : "0 16px"}} src={faqImage} alt="faq-image"/>
          </Box>
        </Box>
      </Box>
      <Grid container justifyContent="center" columns={10} columnSpacing={store.isMobile ? 0 : 5} sx={{width: "100%", maxWidth: maxWidth, mt: !store.isMobile ? -4 : -3, mx: "auto"}}>
        {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
        {!store.isMobile && <Grid item xs={2} sx={{pl: "0px !important"}}>
          <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", position: "sticky", top: scrollPosition, p: 4}}>
            <SectionMenu section={FaqSection.ORDER}/>
            <SectionMenu section={FaqSection.DELIVERY}/>
            <SectionMenu section={FaqSection.STOCK}/>
            <SectionMenu section={FaqSection.TOP_UP}/>
            <SectionMenu section={FaqSection.TECHNICAL}/>
          </Card>
        </Grid>}
        <Grid item xs={10} sm={8} sx={{mb: 7}}>
          <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mb: 6, mx: store.isMobile ? 2 : "0px"}}>
            <FaqCardTitle section={FaqSection.ORDER} show={showOrder} setShow={setShowOrder}/>
            {(showOrder || !store.isMobile) && <>
              <Grid container columns={6} columnSpacing={store.isMobile ? 0 : 10} rowSpacing={store.isMobile ? 7 : 0} sx={{p: store.isMobile ? 3 : 7}}>
                {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.ORDER} index={1}/>
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: t("faq.order.section_1.text_1")}}/>
                    <ul style={{marginTop: 0, paddingLeft: 20}}>
                      <li><Typography variant="body2" dangerouslySetInnerHTML={{__html: t("faq.order.section_1.item_1")}}/></li>
                      <li><Typography variant="body2">{t("faq.order.section_1.item_2")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.order.section_1.item_3", {reference: store.operation.reference})}</Typography></li>
                      <li><Typography variant="body2">{t("faq.order.section_1.item_4")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.order.section_1.item_5")}</Typography></li>
                    </ul>
                    <Typography variant="body2" sx={{mt: 4}}>{t("faq.order.section_1.text_2")}</Typography>
                  </>
                  <>
                    <FaqCardSectionTitle section={FaqSection.ORDER} index={2} isNotFirst/>
                    <Typography variant="body2">{t("faq.order.section_2.text")}</Typography>
                  </>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.ORDER} index={3}/>
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: t("faq.order.section_3.text_1")}}/>
                    <ul style={{marginTop: 0, paddingLeft: 20}}>
                      <li><Typography variant="body2" dangerouslySetInnerHTML={{__html: t("faq.order.section_3.item_1")}}/></li>
                      <li><Typography variant="body2">{t("faq.order.section_3.item_2", {reference: store.operation.reference})}</Typography></li>
                      <li><Typography variant="body2">{t("faq.order.section_3.item_3")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.order.section_3.item_4")}</Typography></li>
                    </ul>
                    <Typography variant="body2" sx={{mt: 1}}>{t("faq.order.section_3.text_2")}</Typography>
                  </>
                  <>
                    <FaqCardSectionTitle section={FaqSection.ORDER} index={4} isNotFirst/>
                    <Typography variant="body2" sx={{mb: 4}} dangerouslySetInnerHTML={{__html: t("faq.order.section_4.text_1")}}/>
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: t("faq.order.section_4.text_2")}}/>
                  </>
                </Grid>
              </Grid>
              <AskForMore/>
            </>}
          </Card>
          <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mb: 6, mx: store.isMobile ? 2 : "0px"}}>
            <FaqCardTitle section={FaqSection.DELIVERY} show={showDelivery} setShow={setShowDelivery}/>
            {(showDelivery || !store.isMobile) && <>
              <Grid container columns={6} columnSpacing={store.isMobile ? 0 : 10} rowSpacing={store.isMobile ? 7 : 0} sx={{p: store.isMobile ? 3 : 7}}>
                {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.DELIVERY} index={1}/>
                    <Typography variant="body2">{t("faq.delivery.section_1.text")}</Typography>
                  </>
                  <>
                    <FaqCardSectionTitle section={FaqSection.DELIVERY} index={2} isNotFirst/>
                    <Typography variant="body2">{t("faq.delivery.section_2.text_1")}</Typography>
                    <Typography variant="body2">{t("faq.delivery.section_2.text_2")}</Typography>
                  </>
                  <>
                    <FaqCardSectionTitle section={FaqSection.DELIVERY} index={3} isNotFirst/>
                    <Typography variant="body2" sx={{mt: 1}}>{t("faq.delivery.section_3.text")}</Typography>
                  </>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.DELIVERY} index={4}/>
                    <Typography variant="body2">{t("faq.delivery.section_4.text")}</Typography>
                    <ul style={{marginTop: 0, paddingLeft: 20}}>
                      <li><Typography variant="body2">{t("faq.delivery.section_4.item_1")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.delivery.section_4.item_2", {reference: store.operation.reference})}</Typography></li>
                    </ul>
                  </>
                </Grid>
              </Grid>
              <AskForMore/>
            </>}
          </Card>
          <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mb: 6, mx: store.isMobile ? 2 : "0px"}}>
            <FaqCardTitle section={FaqSection.STOCK} show={showStock} setShow={setShowStock}/>
            {(showStock || !store.isMobile) && <>
              <Grid container columns={6} columnSpacing={store.isMobile ? 0 : 10} rowSpacing={store.isMobile ? 7 : 0} sx={{p: store.isMobile ? 3 : 7}}>
                {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.STOCK} index={1}/>
                    <Typography variant="body2" sx={{mb: 4}}>{t("faq.stock.section_1.text_1")}</Typography>
                    <Typography variant="body2" sx={{mb: 4}}>{t("faq.stock.section_1.text_2")}</Typography>
                    <Typography variant="body2">{t("faq.stock.section_1.text_3")}</Typography>
                  </>
                  <>
                    <FaqCardSectionTitle section={FaqSection.STOCK} index={2} isNotFirst/>
                    <Typography variant="body2">{t("faq.stock.section_2.text_1")}</Typography>
                    <Typography variant="body2">{t("faq.stock.section_2.text_2")}</Typography>
                    <ul style={{marginTop: 0, paddingLeft: 20}}>
                      <li><Typography variant="body2">{t("faq.stock.section_2.item_1")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.stock.section_2.item_2")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.stock.section_2.item_3")}</Typography></li>
                    </ul>
                    <Typography variant="body2" sx={{mt: 4}}>{t("faq.stock.section_2.text_3")}</Typography>
                  </>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.STOCK} index={3}/>
                    <Typography variant="body2" sx={{mb: 4}}>{t("faq.stock.section_3.text_1")}</Typography>
                    <Typography variant="body2" sx={{mb: 4}}>{t("faq.stock.section_3.text_2")}</Typography>
                    <Typography variant="body2">{t("faq.stock.section_3.text_3")}</Typography>
                  </>
                </Grid>
              </Grid>
              <AskForMore/>
            </>}
          </Card>
          <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mb: 6, mx: store.isMobile ? 2 : "0px"}}>
            <FaqCardTitle section={FaqSection.TOP_UP} show={showTopUp} setShow={setShowTopUp}/>
            {(showTopUp || !store.isMobile) && <>
              <Grid container columns={6} columnSpacing={store.isMobile ? 0 : 10} rowSpacing={store.isMobile ? 7 : 0} sx={{p: store.isMobile ? 3 : 7}}>
                {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.TOP_UP} index={1}/>
                    <ol style={{marginTop: 0, paddingLeft: 20, fontFamily: "Mulish", fontSize: 14}}>
                      <li><Typography variant="body2">{t("faq.top_up.section_1.item_1")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.top_up.section_1.item_2")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.top_up.section_1.item_3")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.top_up.section_1.item_4")}</Typography></li>
                    </ol>
                  </>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.TOP_UP} index={2}/>
                    <Typography variant="body2">{t("faq.top_up.section_2.text")}</Typography>
                  </>
                </Grid>
              </Grid>
              <AskForMore/>
            </>}
          </Card>
          <Card sx={{boxShadow: 1, border: "1px solid", borderColor: "ornament.dark", mx: store.isMobile ? 2 : "0px"}}>
            <FaqCardTitle section={FaqSection.TECHNICAL} show={showTechnical} setShow={setShowTechnical}/>
            {(showTechnical || !store.isMobile) && <>
              <Grid container columns={6} columnSpacing={store.isMobile ? 0 : 10} rowSpacing={store.isMobile ? 7 : 0} sx={{p: store.isMobile ? 3 : 7}}>
                {/** Le important ici ne doit pas être supprimer pour éviter un padding left forcé par MUI */}
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.TECHNICAL} index={1}/>
                    <Typography variant="body2">{t("faq.technical.section_1.text_1")}</Typography>
                    <Typography variant="body2">{t("faq.technical.section_1.text_2")}</Typography>
                    <ul style={{marginTop: 0, paddingLeft: 20}}>
                      <li><Typography variant="body2">{t("faq.technical.section_1.item_1")}</Typography></li>
                      <li><Typography variant="body2">{t("faq.technical.section_1.item_2")}</Typography></li>
                    </ul>
                  </>
                  <>
                    <Typography variant="body1" sx={{fontWeight: "bold", mb: 1, mt: 8}}>
                      {t("faq.technical.section_2.title")}
                    </Typography>
                    <Typography variant="body2" dangerouslySetInnerHTML={{__html: t("faq.technical.section_2.text_1", {reference: store.operation.reference})}}/>
                    <Typography variant="body2">{t("faq.technical.section_2.text_2")}</Typography>
                  </>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <>
                    <FaqCardSectionTitle section={FaqSection.TECHNICAL} index={3}/>
                    <Typography variant="body2">{t("faq.technical.section_3.text_1")}</Typography>
                    <Typography variant="body2">{t("faq.technical.section_3.text_2")}</Typography>
                    <Typography variant="body2">{t("faq.technical.section_3.text_3")}</Typography>
                  </>
                </Grid>
              </Grid>
              <AskForMore/>
            </>}
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
