import React from "react";
import Header from "../../patterns/header/header";
import LanguageSelector from "../../components/languageSelector/languageSelector";
import {CookieConsent} from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import {saveCookieConsent} from "../../services/AuthenticationService";
import {Box, Theme, Typography} from "@mui/material";
import Footer from "../../patterns/footer/footer";

const privateRoute = (component: JSX.Element, theme: Theme, isMobile: boolean): JSX.Element => {

  function CookieFooter(): JSX.Element {
    const {t} = useTranslation();
    const handleAcceptCookie = (): void => {
      saveCookieConsent();
    }
    
    return (
      <CookieConsent
        location="bottom"
        buttonText={t("cookie.button")}
        cookieName={`joozyFoCookie-${window.location.hostname}`}
        onAccept={handleAcceptCookie}
        style={{
          backgroundColor: theme.palette.primary.main,
          padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
          boxSizing: "border-box"
        }}
        contentStyle={{
          margin: 0
        }}
        buttonStyle={{
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.contrastText,
          borderRadius: 4,
          margin: 0
        }}
      >
        <Typography variant="body2" color="primary.contrastText">
          {t("cookie.content")}
        </Typography>
      </CookieConsent>
    )
  }

  return (
    <>
      <Header/>
      <LanguageSelector />
      <CookieFooter/>
      <Box sx={{minHeight: !isMobile ? "calc(100vh - 181px)" : "calc(100vh - 292px)"}}>
        {component}
      </Box>
      <Footer/>
    </>
  )
}

export default privateRoute;
