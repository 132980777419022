import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Card, Grid, Typography} from "@mui/material";
import {AppContext} from "../../App";
import orderSuccess from "../../assets/order_success.svg";
import {TOrder, TOrderLine} from "../../common/struct/models/TOrder";
import {globalStoreReducer} from "../../common/methods/context/globalStoreReducer";
import {getOperationParticipantId} from "../../common/struct/globalVar";
import {PUBLIC_URL} from "../../common/struct/urlManager";
import {StoreActions} from "../../common/struct/store";
import {PrimaryBigButton} from "../../components/buttons/mainButton";
import CardMediaLoader from "../../components/image/cardMediaLoader";
import Loader from "../../components/loader/loader";
import {getMinimalUserInfo, getUserOrder, updateParticipantBasket} from "../../services/ParticipantService";
import {ShippingAddressOverview} from "../basket/shippingAddressOverview";
import BasketTotal from "../basket/basketTotal";
import {BillingAddressOverview} from "../basket/billingAddressOverview";

function GiftOverview(props: {line: TOrderLine}): JSX.Element {
  const {t} = useTranslation();
  const [store] = useContext<any>(AppContext);
  return (
    <Box sx={{display: "flex", flexDirection: "column", mt: 4}}>
      <Box sx={{display: "flex", alignItems: "baseline", justifyContent: "space-between", mb: 0}}>
        <Typography variant="body1" color="neutral.dark">{props.line.name}</Typography>
        {!store.operation.shop.hidePricing && <Box sx={{display: "flex", alignItems: "start"}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold", mr: 0}}>
            {props.line.pointsPrice}
          </Typography>
          <Typography variant="caption" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("order.points")}
          </Typography>
        </Box>}
      </Box>
      <Typography variant="body2" color="neutral.main" sx={{fontWeight: "unset"}}
        dangerouslySetInnerHTML={{__html: t("order.quantity", {quantity: props.line.quantity})}}/>
    </Box>
  )
}

export default function OrderDone(): JSX.Element {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {giftOrderId} = useParams<string>();
  const STORE = useContext<any>(AppContext);

  useEffect(() => {
    document.title = t("tab_title.order_confirm");
  }, []);

  const [store] = STORE;
  const [order, setOrder] = useState<TOrder|null>(null);
  const [total, setTotal] = useState(0);
  const [monetaryComplement, setMonetaryComplement] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTotal(store.basketTotal??0);
    setMonetaryComplement(store.basketMonetaryComplement??0);
  }, [store.basketTotal, store.basketMonetaryComplement])

  useEffect(() => {
    if (giftOrderId == null) {
      navigate(PUBLIC_URL.HOME);
    } else {
      const operationParticipantId = getOperationParticipantId();
      if (!operationParticipantId) {
        navigate(PUBLIC_URL.HOME);
        return;
      }

      getUserOrder(giftOrderId)
        .then(order => {
          setOrder(order);
          return updateParticipantBasket(operationParticipantId, [])
        })
        .then(() => getMinimalUserInfo(operationParticipantId))
        .then(user => {
          globalStoreReducer(STORE, StoreActions.LOGIN, {user: user});
          globalStoreReducer(STORE, StoreActions.EMPTY_BASKET);
        })
        .finally(() => setLoading(false))
        .catch(() => navigate(PUBLIC_URL.HOME))
    }
  }, [])

  const paddingH = store.isMobile ? 2 : 5;
  const paddingV = store.isMobile ? 4 : 6;
  const redirectToAccount = (): void => {
    navigate(PUBLIC_URL.ACCOUNT);
  }

  return loading || !order ? <Loader size={75} sx={{position: "sticky", top: "0%", height: "100vh", mt: "-178px"}}/> :
    <Grid container justifyContent="center" columns={10} columnSpacing={5} sx={{py: paddingV, px: paddingH}}>
      <Grid item xs={10} sm={6} sx={{display: "flex", flexDirection: "column", mb: store.isMobile ? 6 : "0px"}}>
        <Card sx={{border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: store.isMobile ? "column" : "row", p: store.isMobile ? 3 : 6}}>
          <CardMediaLoader image={orderSuccess} isMobile={store.isMobile} sx={{width: 160, height: 160}}/>
          <Box sx={{display: "flex", flexDirection: "column", ml: store.isMobile ? "0px" : 6}}>
            <Typography variant="h2" color="neutral.dark" sx={{fontWeight: "bold", mb: 3, mt: store.isMobile ? 4 : "0px", textAlign: store.isMobile ? "center" : "left"}}>
              {t("order.thanks", {name: store.user.participant.firstName})}
            </Typography>
            <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "unset", mb: 3}}
              dangerouslySetInnerHTML={{__html: t("order.information", {email: store.user.participant.email})}}/>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={10} sm={3} sx={{ml: store.isMobile ? "0px" : 6}}>
        <Card sx={{border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column", p: store.isMobile ? 3 : 6}}>
          <Typography variant="body1" color="neutral.dark" sx={{fontWeight: "bold"}}>
            {t("order.date", {date: new Date(order.createdAt)})}
          </Typography>
          <Box sx={{display: "flex", flexDirection: "column", pb: 4}}>
            {order.lines.map(line => <GiftOverview line={line} key={line.name}/>)}
          </Box>
          <ShippingAddressOverview address={order.deliveryAddress} sx={{py: 4, borderTop: "1px solid", borderColor: "ornament.dark"}}/>
          <BillingAddressOverview address={order.invoiceAddress} sx={{py: 4, borderTop: "1px solid", borderColor: "ornament.dark"}}/>
          {!store.operation.shop.hidePricing && <BasketTotal total={total} complement={monetaryComplement} sx={{py: 4}}/>}
        </Card>
        <Card sx={{border: "1px solid", borderColor: "ornament.dark", display: "flex", flexDirection: "column", p: store.isMobile ? 3 : 6, mt: 4}}>
          <Typography variant="body1" sx={{mb: 4}}>{t("order.tracking")}</Typography>
          <PrimaryBigButton action={redirectToAccount} label={t("order.access_account")}/>
        </Card>
      </Grid>
    </Grid>
}
